export function setCampaignId(campaign) {
  var cookieId
  if (campaign !== undefined) {
    cookieId = campaign
  } else {
    var sPageURL = decodeURIComponent(window.location.search.substring(1))
    var sURLVariables = sPageURL.split("&")
    var sParameterName
    var i
    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split("=")
      if (sParameterName[0] === "_a") {
        cookieId = sParameterName[1]
      }
    }
  }
  console.log("campaign: " + cookieId)
  if (cookieId !== undefined) {
    var date = new Date()
    //Set cookie lifetime to 90 days
    date.setTime(date.getTime() + (90 * 24 * 60 * 60 * 1000))
    var expire = ";expires=" + date.toGMTString()
    document.cookie = "campaign=" + cookieId + expire + ";domain=.bizyness.fr;path=/"
  }
}

export function getCampaignId() {
  if (typeof document !== "undefined") {
    var b = document.cookie.match("(^|;)\\s*campaign\\s*=\\s*([^;]+)")
    return b ? b.pop() : ""
  }
}