// React Basic and Bootstrap
import React, { useState } from "react"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { Button, Col, Row } from "reactstrap"
import { AvFeedback, AvForm, AvGroup, AvInput } from "availity-reactstrap-validation"
//Import Icons
// import images
import { getAffiliateId, getTags } from "../assets/javascript/affiliate"
import { getCampaignId } from "../assets/javascript/campaign"
import PropTypes from "prop-types"
import { useMixpanel } from "gatsby-plugin-mixpanel"

const SignupForm = ({ id, affiliateId, tagsId, couponsId, planId, signin, variantId, campaignId }) => {

  const mixpanel = useMixpanel()

  const variants = [{
    text: "Commencez gratuitement",
    paddingRight: "250px"
  }, {
    text: "Commencer",
    paddingRight: "150px"
  }]

  const { site } = useStaticQuery(query)

  const affData = {}

  if (getAffiliateId()) {
    affData.affiliateVal = getAffiliateId()
  }

  if (getTags()) {
    affData.tagsVal = getTags()
  }

  if (getCampaignId()) {
    affData.campaignVal = getCampaignId()
  }

  if (affiliateId) {
    affData.affiliateVal = affiliateId
  }

  if (tagsId) {
    affData.tagsVal = tagsId
  }

  if (campaignId) {
    affData.campaignVal = campaignId
  }

  if (couponsId) {
    affData.couponsVal = couponsId
  }

  if (planId) {
    affData.planVal = planId
  }

  const [formState, setFormState] = useState({ error: false, running: false })

  function postToApi(formData) {
    return fetch(site.siteMetadata.apiUrl + `/register`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(formData) // body data type must match "Content-Type" header
    })
  }

  function handleSubmit(event, errors, values) {
    if (errors.length === 0) {
      setFormState({ running: true })
      postToApi(values).then(response => {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return response
      }).then(response => {
        response.json().then(data => {
          if (data.user) {
            mixpanel.track("Sign Up", {
              "id": id,
              "affiliate": affData.affiliateVal,
              "tags": affData.tagsVal,
              "coupons": affData.couponsVal,
              "plan": affData.planVal,
              "campaign": affData.campaignVal
            })
            mixpanel.alias(data.user.id)
            navigate("/bienvenue/")
          } else if (data.error === "exist") {
            setFormState({ error: true, message: "Un compte existe déjà avec cette adresse email", running: false })
          } else {
            setFormState({
              error: true,
              message: "Une erreur s'est produite, merci de nous contacter par email à contact@bizyness.fr",
              running: false
            })
          }
        })
      }).catch(error => {
        setFormState({
          error: true,
          message: "Une erreur s'est produite, merci de nous contacter par email à contact@bizyness.fr",
          running: false
        })
      })
    }
  }


  const Error = (data) => (
    <div className="alert alert-danger fade show mt-2 text-center" role="alert">{data.message}</div>
  )

  return <>
    <AvForm className="login-form mt-2 text-left d-none d-sm-block" onSubmit={handleSubmit}>
      <AvInput type="hidden" name="coupons" value={affData.couponsVal}/>
      <AvInput type="hidden" name="tags" value={affData.tagsVal}/>
      <AvInput type="hidden" name="affiliate" value={affData.affiliateVal}/>
      <AvInput type="hidden" name="plan" value={affData.planVal}/>
      <Row className="subcribe-form">
        <Col md="12">
          <AvGroup>
            <AvInput type="email" className="rounded-pill" name="email"
                     placeholder="Entrez votre adresse email"
                     required style={{ paddingRight: variants[variantId].paddingRight }}/>
            <Button color="primary" className="btn btn-pills btn-primary"
                    disabled={formState.running}>{variants[variantId].text}</Button>
            <AvFeedback className="ml-4">Votre email n'est pas valide</AvFeedback>
            {formState.error && <Error message={formState.message}/>}
          </AvGroup>
        </Col>

        {signin && <Col xs="12" className="text-center">
          <p className="mb-0">
            <small className="text-dark mr-2">Vous avez déjà un compte ?</small>
            <a href="https://app.bizyness.fr/#/login" className="text-dark font-weight-bold">Se
              connecter</a></p>
        </Col>}
      </Row>
    </AvForm>
    <AvForm className="login-form mt-2 text-left d-block d-sm-none" onSubmit={handleSubmit}>
      <AvInput type="hidden" name="coupons" value={affData.couponsVal}/>
      <AvInput type="hidden" name="tags" value={affData.tagsVal}/>
      <AvInput type="hidden" name="affiliate" value={affData.affiliateVal}/>
      <AvInput type="hidden" name="plan" value={affData.planVal}/>
      <Row className="subcribe-form">
        <Col md="12">
          <AvGroup>
            <AvInput type="email" name="email"
                     placeholder="Entrez votre adresse email"
                     required style={{ paddingRight: "30px" }}/>
            <Button color="primary" className="btn btn-block btn-primary mobile"
                    disabled={formState.running}>{variants[variantId].text}</Button>
            <AvFeedback className="ml-4">Votre email n'est pas valide</AvFeedback>
            {formState.error && <Error message={formState.message}/>}
          </AvGroup>
        </Col>

        {signin && <Col xs="12" className="text-center">
          <p className="mb-0">
            <small className="text-dark mr-2">Vous avez déjà un compte ?</small>
            <a href="https://app.bizyness.fr/#/login" className="text-dark font-weight-bold">Se
              connecter</a></p>
        </Col>}
      </Row>
    </AvForm>
  </>
}

export default SignupForm

SignupForm.propTypes = {
  id: PropTypes.string,
  affiliateId: PropTypes.string,
  tagsId: PropTypes.string,
  couponsId: PropTypes.string,
  planId: PropTypes.string,
  signin: PropTypes.bool,
  variantId: PropTypes.number,
  campaignId: PropTypes.string
}

SignupForm.defaultProps = {
  signin: true,
  variantId: 0
}

const query = graphql`
 query SignupForm {
  site {
    siteMetadata {
      apiUrl
    }
  }
}
`
